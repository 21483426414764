import React, { Component } from 'react';
import Content from '../common/containers/Content';
import PublicPage from '../common/containers/PublicPage';
import Ajax from '../common/ajax';
import Config from '../config';
import Waiting from '../common/containers/Waiting';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';

class FaqPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: '',
    };
  }

  componentDidMount() {
    this.fetchHTML();
  }

  fetchHTML() {
    Ajax.get(`${Config.apiHost}help/faq`).done((html) => {
      this.setState({
        ...this.state,
        htmlContent: html,
      });
    });
  }

  render() {
    return (
      <PublicPage title="FAQ">
        <Content>
          <Row>
            <Col md={8} mdOffset={2}>
              <Waiting isProcessing={!this.state.htmlContent}>
                <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />
              </Waiting>
            </Col>
          </Row>
        </Content>
      </PublicPage>
    );
  }
}

export default FaqPage;
